import {Injectable} from '@angular/core';
import {User} from './user';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {SocialLoginApi} from '../@core/backend/common/api/social-login.api';
import {FacebookLoginProvider, GoogleLoginProvider, SocialAuthService} from 'angularx-social-login';
import {environment} from 'src/environments/environment';
import {UserApi} from '../@core/backend/common/api/user.api';
import {NotificationsData} from '../@core/interfaces/common/notifications';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  endpoint = environment.apiUrl;
  currentUser: User;
  private currentUserSubject: BehaviorSubject<User>;


  constructor(
    private http: HttpClient,
    public router: Router,
    private socialLoginApi: SocialLoginApi,
    private socialAuthService: SocialAuthService,
    private userApi: UserApi,
    private notificationService: NotificationsData
  ) {
    this.currentUser = {} as User;
    this.currentUserSubject = new BehaviorSubject<User>(this.currentUser);
  }

  // Sign-up
  register(user: User): Observable<any> {
    const api = `${this.endpoint}/register`;
    return this.http.post(api, user);
  }

  // Sign-in
  logIn(user: User): any {
    return this.http.post<any>(`${this.endpoint}/login`, user)
      .subscribe((result: any) => {

        if (result.token) {
          localStorage.setItem('access_token', result.token);
          this.setCurrentUser();
          this.router.navigate(['market/home']);
        } else {
          this.notificationService.error('invalidLogin');
        }
      });
  }

  getToken(): string | null {
    return localStorage.getItem('access_token');
  }

  isLoggedIn(): boolean {
    const authToken = localStorage.getItem('access_token');
    if (authToken !== null && authToken !== undefined && authToken !== '' && authToken !== 'undefined'){
      if (!this.currentUser.getEmail){
        this.setCurrentUser();
      }
      return true;
    } else {
      return false;
    }
  }

  getCurrentUser(): Observable<User> {
    return this.currentUserSubject.asObservable();
  }

  setCurrentUser(): void {
    this.userApi.getCurrent().subscribe(usr => {
      this.currentUser = usr;
      this.currentUserSubject.next(usr);
    });
  }

  logout(): void {
    const removeToken = localStorage.removeItem('access_token');
    if (removeToken == null) {
      this.router.navigate(['auth/login']);
    }
  }

  socialLoginGoogle(): any {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(r => {
      const token = r.authToken;
      this.socialLoginApi.loginGoogle(token).subscribe((response: any) => {
        if (response.hasOwnProperty('token')) {
          localStorage.setItem('access_token', response.token);
          this.router.navigate(['market/home']);
        }
      });
    });
  }

  socialLoginFacebook(): any {
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then(r => {
      const token = r.authToken;
      this.socialLoginApi.loginFacebook(token).subscribe((response: any) => {
        if (response.hasOwnProperty('token')) {
          localStorage.setItem('access_token', response.token);
          this.router.navigate(['market/home']);
        }
      });
    });
  }

  requestPassword(request: any): Observable<any> {
    return this.http.post<any>(`${this.endpoint}/password_reset`, request);
  }

  resetPassword(): Observable<any> {
    console.log('reset password - TODO');
    return new Observable<any>();
  }


  // TODO make common solution for all services
  // Error
  handleError(error: HttpErrorResponse): Observable<string> {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      msg = error.error.message;
    } else {
      // server-side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(msg);
  }
}
