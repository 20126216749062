// singleton
import {Injectable, OnDestroy} from '@angular/core';
import {CityStateData} from '../../../interfaces/market/city-state';
import {BehaviorSubject, Observable} from 'rxjs';
import {City, CityData} from '../../../interfaces/market/city';
import {CartStateData} from '../../../interfaces/market/cart-state';

@Injectable({
  providedIn: 'root',
})
export class CityStateService implements CityStateData, OnDestroy {
  currentCity: City | undefined;

  private currentCitySubject: BehaviorSubject<City>;

  constructor(private cityService: CityData, private cartStateService: CartStateData) {
    this.currentCitySubject = new BehaviorSubject<City>({} as City);
    this.calculateInitialCity();
  }

  // Calculates current city on load
  calculateInitialCity(): void {
    if (this.currentCity == null) {
      const currentCityId = Number(localStorage.getItem('current_city_id'));

      if (currentCityId) {
        this.setCurrentCity(currentCityId);
      } else {
        this.setDefaultCity();
      }
    }
  }

  private setDefaultCity(): void {
    this.cityService.getAll().subscribe(cities => {
      if (cities != null && cities.length > 0) {
        this.setCurrentCity(cities[0].id);
      }
    });
  }

  setCurrentCity(cityId: number): void {
    localStorage.setItem('current_city_id', cityId.toString());
    this.cartStateService.setCurrentCart(cityId);

    this.cityService.get(cityId).subscribe(result => {
      this.currentCity = result;
      this.currentCitySubject.next(result);
    });
  }

  getCurrent(): Observable<City> {
    return this.currentCitySubject.asObservable();
  }

  ngOnDestroy(): void {
    this.currentCitySubject.complete();
  }
}

