import {Observable} from 'rxjs';

export interface Address {
  id: number;
  additional_info: string;
  city_id: number;
  city_name: string;
  latitude: string;
  longitude: string;
  name: string;
  type: number;
  user_id: number;
  created_at: string;
  updated_at: string;
}

export abstract class AddressData {
  abstract getAllAddresses(): Observable<Address[]>;

  abstract getCityAddresses(cityId: number): Observable<Address[]>;

  abstract getAddress(addressId: string): Observable<Address>;

  abstract add(item: any): Observable<any>;

  abstract update(addressId: string, item: any): Observable<any>;

  abstract delete(addressId: any): Observable<any>;
}
