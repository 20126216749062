import {Injectable} from '@angular/core';
import {NotificationsData} from '../../../interfaces/common/notifications';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class NotificationsService extends NotificationsData {

  constructor(private translate: TranslateService, private toastrService: ToastrService) {
    super();
  }

  info(contentTranslationKey: string, titleTranslationKey?: string|null): void {
    const content = this.translate.instant(contentTranslationKey);
    let title = null;

    if (titleTranslationKey) {
      title = this.translate.instant(titleTranslationKey);
    }

    this.toastrService.info(content, title, {
      timeOut: 1500,
    });
  }

  error(contentTranslationKey: string, titleTranslationKey?: string | null): void {
    const content = this.translate.instant(contentTranslationKey);
    let title = null;

    if (titleTranslationKey) {
      title = this.translate.instant(titleTranslationKey);
    }

    this.toastrService.error(content, title, {
      timeOut: 1500,
    });
  }
}
