import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from './http.service';
import {map} from 'rxjs/operators';
import {User} from '../../../../@auth/user';

@Injectable()
export class UserApi {
  private readonly apiController: string = 'user';

  constructor(private api: HttpService) {}

  getCurrent(): Observable<User> {
    return this.api.get(`${this.apiController}`)
      .pipe(map(data => {
        return new User(data.response);
      }));
  }

  get(id: number): Observable<any> {
    return this.api.get(`${this.apiController}/${id}`)
      .pipe(map(data => {
        return { ...data };
      }));
  }

  delete(id: number): Observable<boolean> {
    return this.api.delete(`${this.apiController}/${id}`);
  }

  add(item: any): Observable<any> {
    return this.api.post(this.apiController, item);
  }

  update(item: any): Observable<any> {
    return this.api.put(`${this.apiController}`, item);
  }
}
