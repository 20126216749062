import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {UserApi} from '../api/user.api';
import {UserData} from '../../../interfaces/common/users';
import {AuthService} from '../../../../@auth/auth.service';
import {User} from '../../../../@auth/user';

@Injectable()
export class UserService extends UserData {

  constructor(private api: UserApi, private authService: AuthService) {
    super();
  }

  getCurrentUser(): Observable<User> {
    const authenticated =  this.authService.isLoggedIn();
    return authenticated ? this.api.getCurrent() : new Observable<User>();
  }

  get(id: number): Observable<User> {
    return this.api.get(id);
  }

  create(user: any): Observable<User> {
    return this.api.add(user);
  }

  update(user: any): Observable<User> {
    return this.api.update(user);
  }

  delete(id: number): Observable<boolean> {
    return this.api.delete(id);
  }
}
