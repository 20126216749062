import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserData} from '../../interfaces/common/users';
import {UserService} from './services/user.service';
import {UserApi} from './api/user.api';
import {HttpService} from './api/http.service';
import {SocialLoginApi} from './api/social-login.api';
import {NotificationsData} from '../../interfaces/common/notifications';
import {NotificationsService} from './services/notifications.service';

const API = [
  UserApi, SocialLoginApi, HttpService
];

const SERVICES = [
  {provide: UserData, useClass: UserService},
  {provide: NotificationsData, useClass: NotificationsService},
];

@NgModule({
  imports: [CommonModule],
})
export class CommonBackendModule {
  static forRoot(): ModuleWithProviders<CommonBackendModule> {
    return {
      ngModule: CommonBackendModule,
      providers: [
        ...API,
        ...SERVICES,
      ],
    };
  }
}
