import {Observable} from 'rxjs';

export abstract class CartHistoryData {
  abstract getUserOrderHistory(cityId: number): Observable<CartHistoryOrder[]>;
}


export interface CartHistoryOrder {
  city_id: number;
  created_at: Date;
  id: number;
  total: number;
  items: CartHistoryOrderItem[];
}

export interface CartHistoryOrderItem {
  bouquet_id: number;
  name: string;
  price: number;
  quantity: number;
  total: number;
}
