import {ProductPicture} from './product';
import {Address} from './address';
import {Bank, CreditCard, CreditCardMeta} from './payment';
import {Observable} from 'rxjs';

export abstract class CartData {
  abstract checkOrderFiveDays(model: FiveDaysCheckModel): Observable<any>;
}


export interface Cart {
  id: number;
  name: string;
  products: Array<CartProduct>;
  total: number;
  deliveryAddress: CartDeliveryAddress;
  deliveryDate: CartDeliveryDateTime;
  payment: CartPayment;
}

export interface CartProduct {
  productId: number;
  name: string;
  count: number;
  price: number;
  pictures: ProductPicture[];
  cityId: number;
}

export interface CartDeliveryAddress {
  address: Address | undefined;
  selfPickup: boolean;
  deliveryPrice: number;
}

export interface CartDeliveryDateTime {
  deliveryDate: string;
  deliveryTimeRange: string;
}

export enum PaymentType {
  Paysera = 1,
  Cardinity = 2,
}

export interface CartPayment {
  paymentType: PaymentType;
  creditCardMeta: CreditCardMeta | undefined;
  creditCard: CreditCard | undefined;
  bank: Bank | undefined;
}


export interface FiveDaysCheckModel {
  cityId: number;
  excludeTomorrow: boolean;
  products: CartProduct[];
}
