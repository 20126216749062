import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CartHistoryApi} from '../api/cart-history.api';
import {CartHistoryData, CartHistoryOrder} from '../../../interfaces/market/cart-history';

@Injectable()
export class CartHistoryService implements CartHistoryData {
  constructor(private api: CartHistoryApi) {
  }

  getUserOrderHistory(cityId: number): Observable<CartHistoryOrder[]> {
    return this.api.getUserOrderHistory(cityId);
  }
}
