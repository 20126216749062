import {Observable} from 'rxjs';
import {CartProduct} from './cart';

export interface Order {
  delivery_date: string | undefined;
  bouquets: CartProduct[];
  address_id: number | undefined;
  city_id: number | undefined;
  delivery_time: string | undefined;
  additional_info: string | undefined;
  is_self_pickup: boolean | undefined;
  delivery_pickup_instructions: string | undefined;
}

export interface CardinityNewCard {
  pan: string | undefined;
  exp_year: string | undefined;
  exp_month: string | undefined;
  cvc: string | undefined;
  holder: string | undefined;
}



export abstract class OrderData {
  abstract getCardinityNewCardData(orderId: number, data: CardinityNewCard): Observable<any>;

  abstract getCardinityOldCardData(orderId: number, cardId: number): Observable<any>;

  abstract getPayseraData(orderId: number, method: string): Observable<string | undefined>;

  abstract create(order: Order): Observable<number | undefined>;
}
