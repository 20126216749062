import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from '../../common/api/http.service';

@Injectable()
export class ProductApi {
  private readonly apiController: string = `bouquet`;
  private readonly apiCityController: string = 'city/${this.cityId}/bouquets';

  constructor(private api: HttpService) {
  }

  getFullCityData(cityId: string): Observable<any> {
    const templateVars = {
      cityId
    };
    const url = this.fillTemplate(this.apiCityController, templateVars);
    return this.api.get(url);
  }

  getTypes(): Observable<any> {
    return this.api.get(`types`);
  }

  getSuppliers(cityId: number): Observable<any> {
    return this.api.get(`city/${cityId}/suppliers`);
  }

  getSupplier(supplierId: number): Observable<any> {
    return this.api.get(`suppliers/${supplierId}`);
  }

  getSupplierProducts(supplierId: number): Observable<any> {
    return this.api.get(`bouquets/supplier/${supplierId}`);
  }

  get(id: number): Observable<any> {
    return this.api.get(`${this.apiController}/${id}`);
  }

  getProducts(cityId: number): Observable<any> {
    return this.api.get(`city/${cityId}/bouquets`);
  }

  getProductsByProductsId(productsIds: string[]): Observable<any> {
    return this.api.post(`bouquet/get_by_ids`, { bouquet_ids: productsIds });
  }

  getProduct(productId: number): Observable<any> {
    return this.api.get(`bouquet/${productId}`);
  }

  getProductsByType(cityId: number, typeId: number): Observable<any> {
    return this.api.get(`city/${cityId}/bouquets/type/${typeId}`);
  }

  searchProducts(cityId: number, term: string, limit: number): Observable<any> {
    let url = `city/${cityId}/search_bouquets`;

    if (term) {
      url += `/${term}?limit=${limit}`;
    }

    return this.api.get(url);
  }

  fillTemplate = (templateString: string, templateVars: any) => {
    return new Function('return `' + templateString + '`;').call(templateVars);
  };
}
