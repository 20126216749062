import {Observable} from 'rxjs';
import {User} from '../../../@auth/user';

export abstract class UserData {
  abstract getCurrentUser(): Observable<User>;
  abstract get(id: number): Observable<User>;
  abstract update(user: User): Observable<User>;
  abstract create(user: User): Observable<User>;
  abstract delete(id: number): Observable<boolean>;
}
