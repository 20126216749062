import {Injectable} from '@angular/core';
import {HttpService} from '../../common/api/http.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Order} from '../../../interfaces/market/order';

@Injectable()
export class OrderApi {
  constructor(private api: HttpService) {
  }

  paysera(orderId: number, method: string): Observable<any> {
    return this.api.get(`payment/order/${orderId}/method/${method}`)
      .pipe(map(data => {

        if (data?.response) {
          return data.response;
        }

        return undefined;
      }));
  }

  cardinityNewCard(orderId: number, model: object): Observable<any> {
    return this.api.post(`cardinity_payment/order/${orderId}`, model);
  }

  cardinityOldCard(orderId: number, cardId: number): Observable<any> {
    return this.api.get(`cardinity_payment/order/${orderId}/card/${cardId}`);
  }

  create(model: Order): Observable<any> {
    return this.api.post(`order`, model)
      .pipe(map(data => {

        if (data?.response?.order_id) {
          return Number(data.response.order_id);
        }

        return undefined;
      }));
  }
}
