import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class HttpService {
    get apiUrl(): string {
    return environment.apiUrl;
  }

  constructor(private http: HttpClient, private translateService: TranslateService) {

  }

  private getCurrentLanguage(): string {
    if (this.translateService.currentLang === undefined) {
      return 'lt';
    } else {
      return this.translateService.currentLang;
    }
  }

  getServerData(endpoint: string, options?: any): Observable<any> {
    if (options === undefined) {
      options = {headers: this.getHeaders()};

    }

    return this.http.get(`${this.apiUrl}/${endpoint}`, options);
  }

  get(endpoint: string, options?: any): Observable<any> {
    if (options === undefined) {
      options = {headers: this.getHeaders()};
    }

    return this.http.get(`${this.apiUrl}/${endpoint}`, options);
  }

  post(endpoint: string, data: any, options?: any): Observable<any> {
    if (options === undefined) {
      options = {headers: this.getHeaders()};
    }

    return this.http.post(`${this.apiUrl}/${endpoint}`, data, options);
  }

  put(endpoint: string, data: any, options?: any): Observable<any> {
    if (options === undefined) {
      options = {headers: this.getHeaders()};
    }
    return this.http.put(`${this.apiUrl}/${endpoint}`, data, options);
  }

  delete(endpoint: string, options?: any): Observable<any> {
    if (options === undefined) {
      options = {headers: this.getHeaders()};
    }

    return this.http.delete(`${this.apiUrl}/${endpoint}`, options);
  }

  private getHeaders(): HttpHeaders {
    return new HttpHeaders().set('Accept-Language', this.getCurrentLanguage());
  }
}
