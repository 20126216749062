import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {throwIfAlreadyLoaded} from './module-import-guard';
import {CommonBackendModule} from './backend/common/common-backend.module';
import {MarketBackendModule} from './backend/market/market-backend.module';
import {UserService} from './backend/common/services/user.service';

export const CORE_PROVIDERS = [
// @ts-ignore
  ...MarketBackendModule.forRoot().providers,
  ...CommonBackendModule.forRoot().providers,
];

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
  ],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ...CORE_PROVIDERS,
        UserService,
      ],
    };
  }
}
