import {Observable} from 'rxjs';
import {Cart, CartProduct, PaymentType} from './cart';
import {Address} from './address';
import {Bank, CreditCard, CreditCardMeta} from './payment';
import {City} from './city';

export abstract class CartStateData {
  abstract getCurrent(): Observable<Cart>;
  abstract setCurrentCart(cartId: number): void;
  abstract addProductToCurrentCart(product: CartProduct): void;
  abstract subtractProductFromCurrentCart(productId: number): void;
  abstract isProductInCurrentCart(productId: number): boolean;
  abstract getCurrentCartProductCount(productId: number): number;
  abstract getCurrentCartAllProductCount(): number;
  abstract setCurrentCartDeliveryAddress(address: Address| undefined): void;
  abstract getCurrentCartDeliveryAddress(): Address | undefined;
  abstract setCurrentCartDeliveryDate(deliveryDate: string | undefined): void;
  abstract getCurrentCartDeliveryDate(): string | undefined;
  abstract setCurrentCartDeliveryTime(deliveryTime: string | undefined): void;
  abstract getCurrentCartDeliveryTime(): string | undefined;
  abstract setCurrentCartPaymentType(paymentType: PaymentType): void;
  abstract getCurrentCartPaymentType(): PaymentType | undefined;
  abstract setCurrentCartCreditCardMeta(creditCardMeta: CreditCardMeta| undefined): void;
  abstract getCurrentCartCreditCardMeta(): CreditCardMeta | undefined;
  abstract setCurrentCartBank(bank: Bank | undefined): void;
  abstract getCurrentCartBank(): Bank | undefined;
  abstract setCurrentCartIsSelfPickup(type: boolean): void;
  abstract isSelfPickup(): boolean;
  abstract getCurrentCartDeliveryPrice(): number | undefined;
  abstract setCurrentCartDeliveryPrice(price: number): void;
  abstract setCurrentCartCreditCard(creditCard: CreditCard | undefined): void;
  abstract getCurrentCartCreditCard(): CreditCard | undefined;
  abstract getCurrentCartCity(): Observable<City> | undefined;
  abstract getCityCartAllProductCount(cityId: number): number;
  abstract getCityCart(cityId: number): Cart | undefined;

  abstract resetCartDetails(): void;
  abstract resetCart(): void;
  abstract resetCurrentCartProduct(productId: number): void;
}
