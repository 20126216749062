// singleton
import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {Cart, CartDeliveryAddress, CartDeliveryDateTime, CartPayment, CartProduct, PaymentType} from '../../../interfaces/market/cart';
import {CartStateData} from '../../../interfaces/market/cart-state';
import {first} from 'rxjs/operators';
import {City, CityData} from '../../../interfaces/market/city';
import {Address} from '../../../interfaces/market/address';
import {Bank, CreditCard, CreditCardMeta} from '../../../interfaces/market/payment';

@Injectable({
  providedIn: 'root',
})
export class CartStateService implements CartStateData, OnDestroy {
  prefix = 'cart_';
  currentCart: Cart | undefined;
  citiesCarts: { [id: number]: Cart } = {};
  notifier = new Subject();

  private currentCartSubject: BehaviorSubject<Cart>;

  constructor(private cityService: CityData) {
    this.currentCartSubject = new BehaviorSubject<Cart>({} as Cart);

    this.populateCarts();
    this.calculateInitialCart();
  }

  resetCurrentCartProduct(productId: number): void {
    this.subtractProductFromCurrentCart(productId, 99999);
  }

  populateCarts(): void {
    this.cityService.getAll().pipe(first()).subscribe(cities => {
      if (cities) {
        cities.forEach(async (city) => {
          if (!this.isCartSetLocally(city.id)) {
            const cart = this.createNewCart(city);
            this.upsertCartToLocalCarts(city.id, cart);
          }
        });
      }
    });
  }

  isCartSetLocally(cartId: number): boolean {
    const currentCartId = localStorage.getItem(this.prefix + cartId.toString());
    return !!currentCartId;
  }

  // Calculates current cart on load
  calculateInitialCart(): void {
    if (this.currentCart == null) {
      const currentCartId = localStorage.getItem('current_cart_id');

      if (currentCartId) {
        this.setCurrentCartInitial(currentCartId);
      } else {
        this.setDefaultCart();
      }
    }
  }

  createNewCart(city: City): Cart {
    const payment = {} as CartPayment;
    payment.paymentType = PaymentType.Cardinity;
    const cart = {} as Cart;
    cart.id = city.id;
    cart.name = city.name;
    cart.products = [];
    cart.total = 0;
    cart.payment = payment;
    return cart;
  }

  private setDefaultCart(): void {
    const cityId = localStorage.getItem('current_city_id');

    if (cityId){
      this.cityService.get(+cityId).pipe(first()).subscribe(city => {
        if (city) {
          const cart = this.createNewCart(city);
          this.upsertCartToLocalCarts(city.id, cart);
          this.setCurrentCartInitial(this.prefix + cart.id.toString());
          this.notifier.complete();
        }
      });
    }
  }

  private upsertCartToLocalCarts(cityId: number, cart: Cart): void {
    this.citiesCarts[cityId] = cart;
    localStorage.setItem(this.prefix + cityId.toString(), JSON.stringify(cart));
  }

  // This needs to be fixed. Now just left here as a separate near identical method.
  setCurrentCartInitial(cartId: string): void {
    const cartInfo = localStorage.getItem(cartId);
    if (cartInfo) {
      const cart = JSON.parse(cartInfo) as Cart;
      localStorage.setItem('current_cart_id', cartId);
      this.currentCart = cart;
      this.currentCartSubject.next(this.currentCart);
    } else {

    }
  }

  setCurrentCart(cartId: number): void {
    const cartInfo = localStorage.getItem(this.prefix + cartId.toString());

    if (cartInfo) {
      const cart = JSON.parse(cartInfo) as Cart;
      localStorage.setItem('current_cart_id', this.prefix + cartId.toString());
      this.currentCart = cart;
      this.currentCartSubject.next(this.currentCart);
    } else {
      this.setDefaultCart();
    }
  }

  subtractProductFromCurrentCart(productId: number, count: number = 1): void {
    const productFound = this.currentCart?.products.find(product => {
      return product.productId === productId;
    });

    if (productFound) {
      if (productFound.count > count) {
        productFound.count -= count;
      } else {
        const index = this.currentCart?.products.findIndex(product => {
          return product.productId === productId;
        });

        if (index !== undefined && index > -1) {
          this.currentCart?.products.splice(index, 1);
        }
      }
    }
    this.recalculateCurrentCartTotal();
  }

  addProductToCurrentCart(product: CartProduct): void {
    if (product.cityId){
      this.setCurrentCart(product.cityId);
    }

    const productFound = this.currentCart?.products.find(prod => {
      return prod.productId === product.productId;
    });
    if (productFound) {
      productFound.count++;
    } else {
      this.currentCart?.products.push(product);
    }
    this.recalculateCurrentCartTotal();
  }

  getCurrent(): Observable<Cart> {
    return this.currentCartSubject.asObservable();
  }

  private updateCurrentCart(): void {
    if (this.currentCart) {
      this.upsertCartToLocalCarts(this.currentCart?.id, this.currentCart);
      this.currentCartSubject.next(this.currentCart);
    }
  }

  getCurrentCartProductCount(productId: number): number {
    const productFound = this.currentCart?.products.find(product => {
      return product.productId === productId;
    });

    if (productFound) {
      return productFound?.count;
    }

    return 0;
  }

  getCurrentCartAllProductCount(): number {

    const totalProducts = this.currentCart?.products
      .reduce((sum, current) => sum + current.count, 0);

    return totalProducts ? totalProducts : 0;
  }

  getCityCartAllProductCount(cityId: number): number {
    const cityCart = this.getCityCart(cityId);
    const totalProducts = cityCart?.products
      .reduce((sum, current) => sum + current.count, 0);

    return totalProducts ? totalProducts : 0;
  }

  getCityCart(cityId: number): Cart | undefined {
    const cartInfo = localStorage.getItem(this.prefix + cityId.toString());
    if (cartInfo) {
      return JSON.parse(cartInfo) as Cart;
    }
    return undefined;
  }

  recalculateCurrentCartTotal(): void {
    let total = 0;
    this.currentCart?.products.forEach((product) => {
      total += product.count * product.price;
    });

    if (this.currentCart) {
      this.currentCart.total = total;
    }
    this.updateCurrentCart();
  }

  isProductInCurrentCart(productId: number): boolean {
    const productFound = this.currentCart?.products.find(product => {
      return product.productId === productId;
    });
    return !!productFound;
  }

  isSelfPickup(): boolean {
    return this.currentCart?.deliveryAddress?.selfPickup as boolean;
  }

  setCurrentCartDeliveryAddress(address: Address | undefined): void {
    if (this.currentCart) {
      if (!this.currentCart.deliveryAddress) {
        this.currentCart.deliveryAddress = {} as CartDeliveryAddress;
      }
      this.currentCart.deliveryAddress.address = address;
      this.updateCurrentCart();
    }
  }

  getCurrentCartDeliveryAddress(): Address | undefined {
    return this.currentCart?.deliveryAddress?.address;
  }

  setCurrentCartIsSelfPickup(type: boolean): void {
    if (this.currentCart) {
      if (!this.currentCart.deliveryAddress) {
        this.currentCart.deliveryAddress = {} as CartDeliveryAddress;
      }
      this.currentCart.deliveryAddress.selfPickup = type;
      this.updateCurrentCart();
    }
  }

  getCurrentCartDeliveryPrice(): number | undefined {
    return this.currentCart?.deliveryAddress?.deliveryPrice;
  }

  setCurrentCartDeliveryPrice(price: number): void {
    if (this.currentCart) {
      if (!this.currentCart.deliveryAddress) {
        this.currentCart.deliveryAddress = {} as CartDeliveryAddress;
      }
      this.currentCart.deliveryAddress.deliveryPrice = Number(price);
      this.updateCurrentCart();
    }
  }

  setCurrentCartDeliveryDate(deliveryDate: string): void {
    if (this.currentCart) {
      if (!this.currentCart.deliveryDate) {
        this.currentCart.deliveryDate = {} as CartDeliveryDateTime;
      }
      this.currentCart.deliveryDate.deliveryDate = deliveryDate;
      this.updateCurrentCart();
    }
  }

  getCurrentCartDeliveryDate(): string | undefined {
    return this.currentCart?.deliveryDate?.deliveryDate;
  }

  setCurrentCartDeliveryTime(deliveryTime: string): void {
    if (this.currentCart) {
      if (!this.currentCart.deliveryDate) {
        this.currentCart.deliveryDate = {} as CartDeliveryDateTime;
      }
      this.currentCart.deliveryDate.deliveryTimeRange = deliveryTime;
      this.updateCurrentCart();
    }
  }

  getCurrentCartDeliveryTime(): string | undefined {
    return this.currentCart?.deliveryDate?.deliveryTimeRange;
  }

  setCurrentCartPaymentType(paymentType: PaymentType): void {
    if (this.currentCart) {
      if (!this.currentCart.payment) {
        const payment = {} as CartPayment;
        payment.creditCardMeta = {} as CreditCardMeta;
        this.currentCart.payment = payment;
      }
      this.currentCart.payment.paymentType = paymentType;
      this.updateCurrentCart();
    }
  }

  getCurrentCartPaymentType(): PaymentType | undefined {
    return this.currentCart?.payment?.paymentType;
  }

  setCurrentCartCreditCardMeta(creditCardMeta: CreditCardMeta | undefined): void {
    if (this.currentCart) {
      if (!this.currentCart.payment) {
        this.currentCart.payment = {} as CartPayment;
      }

      this.currentCart.payment.creditCardMeta = creditCardMeta;
      this.setCurrentCartPaymentType(PaymentType.Cardinity);
      this.updateCurrentCart();
    }
  }

  getCurrentCartCreditCardMeta(): CreditCardMeta | undefined {
    return this.currentCart?.payment?.creditCardMeta;
  }

  setCurrentCartBank(bank: Bank | undefined): void {
    if (this.currentCart) {
      if (!this.currentCart.payment) {
        const payment = {} as CartPayment;
        payment.creditCardMeta = {} as CreditCardMeta;
        this.currentCart.payment = payment;
      }
      this.currentCart.payment.bank = bank;
      this.setCurrentCartPaymentType(PaymentType.Paysera);
      this.updateCurrentCart();
    }
  }

  getCurrentCartBank(): Bank | undefined {
    return this.currentCart?.payment?.bank;
  }

  ngOnDestroy(): void {
    this.currentCartSubject.complete();
  }

  setCurrentCartCreditCard(creditCard: CreditCard | undefined): void {
    if (this.currentCart) {
      if (!this.currentCart.payment) {
        this.currentCart.payment = {} as CartPayment;
      }

      this.currentCart.payment.creditCard = creditCard;
      this.setCurrentCartPaymentType(PaymentType.Cardinity);
      this.updateCurrentCart();
    }
  }

  getCurrentCartCreditCard(): CreditCard | undefined {
    return this.currentCart?.payment?.creditCard;
  }

  getCurrentCartCity(): Observable<City> | undefined {
    if (this.currentCart) {
      return this.cityService.get(this.currentCart?.id);
    }
    return undefined;
  }

  resetCartDetails(): void {
    if (this.currentCart) {
      this.currentCart.payment = {paymentType: PaymentType.Cardinity} as CartPayment;
      this.currentCart.deliveryAddress = {} as CartDeliveryAddress;
      this.currentCart.deliveryDate = {} as CartDeliveryDateTime;

      this.updateCurrentCart();
    }
  }

  resetCart(): void {
    this.getCurrentCartCity()?.subscribe(city => {
      this.currentCart = this.createNewCart(city);

      this.updateCurrentCart();
    });
  }
}

