// singleton
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LanguageData} from '../../../interfaces/market/language';
import {LanguageApi} from '../api/language.api';

@Injectable({
  providedIn: 'root',
})
export class LanguageService implements LanguageData {
  constructor(private translateService: TranslateService, private languageApi: LanguageApi) {
    const currentLang = this.getLanguage();

    this.initLanguage(currentLang);
  }

  initLanguage(currentLang: string): void {
    this.translateService.setDefaultLang(currentLang);
    this.translateService.currentLang = currentLang;
    localStorage.setItem('current_language', currentLang);
  }

  setLanguage(lang: string): void {
    localStorage.setItem('current_language', lang);
    this.translateService.use(lang);
    this.translateService.currentLang = lang;
    this.languageApi.updateLanguage(lang).subscribe();
  }

  getLanguage(): string {
    let currentLang = localStorage.getItem('current_language');
    if (currentLang === null) {
      if (window.navigator.language === 'en') {
        currentLang = 'en';
      } else {
        currentLang = 'lt';
      }
    }

    return currentLang;
  }

  getAvailableLanguages(): { [key: string]: string } {
    return {
      lt: 'Lietuvių',
      en: 'English',
    };
  }
}

