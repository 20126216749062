export class User {
  get getPhone(): string {
    return this.phone;
  }

  set getPhone(value: string) {
    this.phone = value;
  }
  get getId(): string {
    return this.id;
  }

  set setId(value: string) {
    this.id = value;
  }

  get getName(): string {
    return this.name;
  }

  set setName(value: string) {
    this.name = value;
  }

  get getSurname(): string {
    return this.surname;
  }

  set setSurname(value: string) {
    this.surname = value;
  }

  get getEmail(): string {
    return this.email;
  }

  set setEmail(value: string) {
    this.email = value;
  }

  get getPassword(): string {
    return this.password;
  }

  set setPassword(value: string) {
    this.password = value;
  }

  private id: string;
  private name: string;
  private surname: string;
  private email: string;
  private password: string;
  private phone: string;

  constructor(obj: any) {
    this.id = obj.hasOwnProperty('id') ? obj.id : '';
    this.name = obj.hasOwnProperty('name') ? obj.name : '';
    this.surname = obj.hasOwnProperty('surname') ? obj.surname : '';
    this.email = obj.hasOwnProperty('email') ? obj.email : '';
    this.password = obj.hasOwnProperty('password') ? obj.password : '';
    this.phone = obj.hasOwnProperty('phone') ? obj.phone : '';
  }

  public getFullName(): string {
    return this.name + ' ' + this.surname;
  }
}
