import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from '../../common/api/http.service';
import {map} from 'rxjs/operators';

@Injectable()
export class CityApi {
  constructor(private api: HttpService) {
  }

  getAll(country: number): Observable<any> {
    return this.api.get(`cities/${country}`)
      .pipe(map(data => {
        return data.response;
      }));
  }
}
