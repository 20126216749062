import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {PaymentApi} from '../api/payment.api';
import {CreditCardMeta, PaymentData} from '../../../interfaces/market/payment';

@Injectable()
export class PaymentService implements PaymentData {
  constructor(private api: PaymentApi) {
  }

  getAllPaymentMethods(item: any): Observable<any> {
    return this.api.getAllPaymentMethods({});
  }

  getAllCreditCards(item: any): Observable<CreditCardMeta[]> {
    return this.api.getAllCreditCards({});
  }

  deleteCreditCard(cardId: number): Observable<any> {
    return this.api.deleteCreditCard(cardId);
  }
}
