import {Observable} from 'rxjs';

export interface City {
  id: number;
  name: string;
  order_today_until: number;
  delivery_price: number;
  free_delivery_limit: number;
  order_period: number;
  can_self_pickup: boolean;
  delivery_pickup_instructions: string;
  allow_today_delivery: boolean;
  unavailable_dates_list: string[];
}

export abstract class CityData {
  abstract getAll(): Observable<City[]>;
  abstract get(cityId: number): Observable<City>;
}
