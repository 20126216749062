import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from '../../common/api/http.service';
import {map} from 'rxjs/operators';

@Injectable()
export class CartHistoryApi {
  constructor(private api: HttpService) {
  }

  getUserOrderHistory(cityId: number): Observable<any> {
    return this.api.get(`city/${cityId}/order_history/list`)
      .pipe(map(data => {
        return data.response;
      }));
  }
}
