import {Injectable} from '@angular/core';
import {HttpService} from '../../common/api/http.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class LanguageApi {
  constructor(private api: HttpService) {
  }

  updateLanguage(language: string): Observable<any> {
    return this.api.post(`update_language`, {lang: language})
      .pipe(map(data => {

        if (data?.response) {
          return data.response;
        }

        return undefined;
      }));
  }
}
