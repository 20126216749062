import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CommonBackendModule} from '../common/common-backend.module';
import {ProductApi} from './api/product.api';
import {ProductData} from '../../interfaces/market/product';
import {ProductService} from './services/product.service';
import {CityData} from '../../interfaces/market/city';
import {CityService} from './services/city.service';
import {CityApi} from './api/city.api';
import {CityStateData} from '../../interfaces/market/city-state';
import {CityStateService} from './services/city-state.service';
import {AddressData} from '../../interfaces/market/address';
import {AddressService} from './services/address.service';
import {AddressApi} from './api/address.api';
import {LanguageData} from '../../interfaces/market/language';
import {CartApi} from './api/cart.api';
import {CartStateData} from '../../interfaces/market/cart-state';
import {CartStateService} from './services/cart-state.service';
import {LanguageService} from './services/language.service';
import {SwiperOffsetService} from './services/swiper-offset.service';
import {SwiperOffsetData} from '../../interfaces/common/swiper-offset';
import {PaymentApi} from './api/payment.api';
import {PaymentData} from '../../interfaces/market/payment';
import {PaymentService} from './services/payment.service';
import {CartService} from './services/cart.service';
import {CartData} from '../../interfaces/market/cart';
import {OrderApi} from './api/order.api';
import {OrderData} from '../../interfaces/market/order';
import {OrderService} from './services/order.service';
import {LanguageApi} from './api/language.api';
import {ApplicationStateService} from './services/application-state.service';
import {ApplicationStateData} from '../../interfaces/market/application-state';
import {CartHistoryApi} from './api/cart-history.api';
import {CartHistoryData} from '../../interfaces/market/cart-history';
import {CartHistoryService} from './services/cart-history.service';

const API = [
  ProductApi,
  CityApi,
  AddressApi,
  CartApi,
  PaymentApi,
  OrderApi,
  LanguageApi,
  CartHistoryApi
];

const SERVICES = [
  { provide: ProductData, useClass: ProductService },
  { provide: CityData, useClass: CityService },
  { provide: CartData, useClass: CartService },
  { provide: AddressData, useClass: AddressService },
  { provide: PaymentData, useClass: PaymentService },
  { provide: CityStateData, useClass: CityStateService },
  { provide: LanguageData, useClass: LanguageService },
  { provide: CartStateData, useClass: CartStateService },
  { provide: SwiperOffsetData, useClass: SwiperOffsetService },
  { provide: OrderData, useClass: OrderService },
  { provide: ApplicationStateData, useClass: ApplicationStateService },
  { provide: CartHistoryData, useClass: CartHistoryService },
];

@NgModule({
  imports: [CommonModule, CommonBackendModule],
  exports: [CommonBackendModule],
})

export class MarketBackendModule {
  static forRoot(): ModuleWithProviders<MarketBackendModule> {
    return {
      ngModule: MarketBackendModule,
      providers: [
        ...API,
        ...SERVICES,
      ],
    };
  }
}
