import {ElementRef, Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, fromEvent, Observable} from 'rxjs';
import {SwiperOffsetData} from '../../../interfaces/common/swiper-offset';

@Injectable()
export class SwiperOffsetService implements SwiperOffsetData, OnDestroy {
  private swiperOffsetSubject: BehaviorSubject<number>;

  elementWidth: number;
  elementGap: number;
  element: ElementRef | undefined;

  constructor() {
    this.elementGap = 0;
    this.elementWidth = 0;

    this.swiperOffsetSubject = new BehaviorSubject<number>(0);

    fromEvent(window, 'resize').subscribe(() => {
      this.swiperOffsetSubject.next(this.calculateOffset());
    });
  }

  calculateOffset(): number {
    let windowWidth;
    if (this.element) {
      windowWidth = this.element.nativeElement.offsetWidth;
    } else {
      windowWidth = window.innerWidth;
    }


    const oneItemWidth = this.elementWidth + this.elementGap;

    if (oneItemWidth === 0) {
      return 0;
    }

    const elementsOnScreen = ((windowWidth / oneItemWidth) - 1);

    return -((elementsOnScreen) * oneItemWidth);
  }


  getSwiperOffset(elementWidth: number, elementGap: number, element: ElementRef | undefined): Observable<number> {
    this.elementWidth = elementWidth;
    this.elementGap = elementGap;
    this.element = element;

    this.swiperOffsetSubject.next(this.calculateOffset());

    return this.swiperOffsetSubject.asObservable();
  }

  ngOnDestroy(): void {
    this.swiperOffsetSubject.complete();
  }
}
