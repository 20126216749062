import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LanguageData} from './@core/interfaces/market/language';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy{
  constructor(
    public translate: TranslateService, public languageService: LanguageData
  ) {
    translate.addLangs(Object.keys(languageService.getAvailableLanguages()));
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }
}
