import {Injectable} from '@angular/core';
import {CardinityNewCard, Order, OrderData} from '../../../interfaces/market/order';
import {OrderApi} from '../api/order.api';
import {Observable} from 'rxjs';

@Injectable()
export class OrderService implements OrderData {
  constructor(private orderApi: OrderApi) {
  }

  create(order: Order): Observable<number | undefined> {
    return this.orderApi.create(order);
  }

  getPayseraData(orderId: number, method: string): Observable<string | undefined> {
    return this.orderApi.paysera(orderId, method);
  }

  getCardinityNewCardData(orderId: number, data: CardinityNewCard): Observable<any> {
    return this.orderApi.cardinityNewCard(orderId, data);
  }

  getCardinityOldCardData(orderId: number, cardId: number): Observable<any> {
    return this.orderApi.cardinityOldCard(orderId, cardId);
  }
}
