import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AuthService} from './auth.service';
import {Observable} from 'rxjs';

@Injectable()

export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.filterInterceptorRequest(req)){
      const authToken = this.authService.getToken();
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ` + authToken
        }
      });
    }

    return next.handle(req);
  }

  filterInterceptorRequest(req: HttpRequest<any>): boolean {
    return ['/login', '/register', '/password_reset']
      .some(url => req.url.includes(url));
  }
}
