import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ProductApi} from '../api/product.api';
import {Product, ProductData, Supplier, Type} from '../../../interfaces/market/product';
import {map} from 'rxjs/operators';

@Injectable()
export class ProductService implements ProductData {
  constructor(private api: ProductApi) {
  }

  get(id: number): Observable<Product> {
    return this.api.get(id);
  }

  // TODO create return data model.
  getCityData(cityId: string): Observable<any> {
    return this.api.getFullCityData(cityId);
  }

  getTypes(): Observable<Type[]> {
    return this.api.getTypes().pipe(map(data => {
      return data.response;
    }));
  }

  getSuppliers(cityId: number): Observable<Supplier[]> {
    return this.api.getSuppliers(cityId).pipe(map(data => {
      return data.response;
    }));
  }

  getProducts(cityId: number): Observable<Product[]> {
    return this.api.getProducts(cityId).pipe(map(data => {
      return data.response;
    }));
  }

  getProductsByProductsId(productsIds: string[]): Observable<Product[]> {
    return this.api.getProductsByProductsId(productsIds).pipe(map(data => {
      return data.response;
    }));
  }

  getProductsByType(cityId: number, typeId: number): Observable<Product[]> {
    return this.api.getProductsByType(cityId, typeId).pipe(map(data => {
      return data.response;
    }));
  }

  getSupplier(supplierId: number): Observable<Supplier> {
    return this.api.getSupplier(supplierId);
  }

  getSupplierProducts(supplierId: number): Observable<Product[]> {
    return this.api.getSupplierProducts(supplierId).pipe(map(data => {
      return data.response;
    }));
  }

  getProduct(productId: number): Observable<Product> {
    return this.api.getProduct(productId).pipe(map(data => {
      return data.response;
    }));
  }

  searchProducts(cityId: number, term: string, limit: number): Observable<Product[]> {
    return this.api.searchProducts(cityId, term, limit).pipe(map(data => {
      return data.response;
    }));
  }
}
