import {Observable} from 'rxjs';

export interface CreditCardMeta {
  id: number;
  last_four_digits: string;
  card_brand: string;
}

export interface CreditCard {
  name: string;
  surname: string;
  cardNumber: string;
  expDate: string;
  ccv: string;
}

export interface Bank {
  key: string;
  bank: string;
}

export abstract class PaymentData {
  abstract getAllPaymentMethods(item: any): Observable<any>;
  abstract getAllCreditCards(item: any): Observable<any>;
  abstract deleteCreditCard(cardId: number): Observable<any>;
}
