import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from '../../common/api/http.service';
import {map} from 'rxjs/operators';

@Injectable()
export class AddressApi {
  constructor(private api: HttpService) {
  }

  getAll(): Observable<any> {
    return this.api.get(`all_addresses`)
      .pipe(map(data => {
        return data.response;
      }));
  }

  getCityAddresses(cityId: number): Observable<any> {
    return this.api.get(`city_addresses/${cityId}`)
      .pipe(map(data => {
        return data.response;
      }));
  }

  get(addressId: string): Observable<any> {
    return this.api.get(`address/${addressId}`)
      .pipe(map(data => {
        return data.response;
      }));
  }

  add(item: any): Observable<any> {
    return this.api.post(`address`, item)
      .pipe(map(data => {
        return data.response;
      }));
  }

  update(addressId: string, item: any): Observable<any> {
    return this.api.put(`address/${addressId}`, item)
      .pipe(map(data => {
        return data.response;
      }));
  }

  delete(addressId: any): Observable<any> {
    return this.api.delete(`address/${addressId}`)
      .pipe(map(data => {
        return data.response;
      }));
  }
}
