import {Injectable} from '@angular/core';
import {HttpService} from '../../common/api/http.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {FiveDaysCheckModel} from '../../../interfaces/market/cart';

@Injectable()
export class CartApi {
  constructor(private api: HttpService) {
  }

  checkOrderFiveDays(model: FiveDaysCheckModel): Observable<any> {
    return this.api.post(`order/check_five_days`,
      {
              bouquets: model.products,
              city_id: model.cityId,
              exclude_tomorrow: model.excludeTomorrow
      })
      .pipe(map(data => {
        return data;
      }));
  }
}
