import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from './http.service';

@Injectable()
export class SocialLoginApi {
  private readonly apiController: string = 'login/socialite';

  constructor(private api: HttpService) {}

  loginFacebook(facebookToken: string): Observable<any> {
    return this.api.post(`${this.apiController}`, {
      facebook_token: facebookToken,
    });
  }

  loginGoogle(googleToken: string): Observable<any> {
    return this.api.post(`${this.apiController}`, {
      google_token: googleToken,
    });
  }
}
