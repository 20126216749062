import {Injectable} from '@angular/core';
import {CartData, FiveDaysCheckModel} from '../../../interfaces/market/cart';
import {Observable} from 'rxjs';
import {CartApi} from '../api/cart.api';

// So far it is not used. Created to keep pattern for later server side cart implementation

@Injectable()
export class CartService implements CartData {


  constructor(private api: CartApi) {
  }


  checkOrderFiveDays(model: FiveDaysCheckModel): Observable<any> {
    return this.api.checkOrderFiveDays(model);
  }
}
