import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Address, AddressData} from '../../../interfaces/market/address';
import {AddressApi} from '../api/address.api';

@Injectable()
export class AddressService implements AddressData {
  constructor(private api: AddressApi) {
  }

  getAllAddresses(): Observable<Address[]> {
    return this.api.getAll();
  }

  getCityAddresses(cityId: number): Observable<Address[]> {
    return this.api.getCityAddresses(cityId);
  }

  getAddress(addressId: string): Observable<Address> {
    return this.api.get(addressId);
  }

  add(item: any): Observable<any> {
    return this.api.add(item);
  }

  update(addressId: string, item: any): Observable<any> {
    return this.api.update(addressId, item);
  }

  delete(addressId: any): Observable<any> {
    return this.api.delete(addressId);
  }
}
