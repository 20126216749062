import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {City, CityData} from '../../../interfaces/market/city';
import {CityApi} from '../api/city.api';
import {map} from 'rxjs/operators';

@Injectable()
export class CityService implements CityData {
  // hardcoded country id, which stands for lithuania
  private country = 1;

  constructor(private api: CityApi) {
  }


  getAll(): Observable<City[]> {
    return this.api.getAll(this.country);
  }

  get(cityId: number): Observable<City> {
    return this.api.getAll(this.country).pipe(map(data => {
      if (data == null || data.length === 0) {
        return null;
      }

      for (let i = 0; i < data.length; i++) {
        if (data[i].id === cityId) {
          return data[i];
        }
      }

      return null;
    }));
  }
}
