import {Observable} from 'rxjs';

export interface Product {
  id: number;
  name: string;
  description: string;
  original_price: number;
  price: number;
  types: ProductType[];
  pictures: ProductPicture[];
  city_id: number;
}

export interface ProductPicture {
  path: string;
}

export interface ProductType {
  type: number;
}

export interface Type {
  id: number;
  name: string;
  is_alcohol: boolean;
  icon_url: string;
}

export interface Supplier {
  id: number;
  company_name: string;
  description: string;
  sort_order: number;
  has_alcohol: boolean;
  pictures: Picture[];
}

export interface Picture {
  id: number;
  path: string;
}

export abstract class ProductData {
  abstract get(id: number): Observable<Product>;

  abstract getCityData(cityId: string): Observable<any>;

  abstract getTypes(): Observable<Type[]>;

  abstract getSuppliers(cityId: number): Observable<Supplier[]>;

  abstract getSupplier(supplierId: number): Observable<Supplier>;

  abstract getSupplierProducts(supplierId: number): Observable<Product[]>;

  abstract getProducts(cityId: number): Observable<Product[]>;

  abstract getProductsByProductsId(productsIds: string[]): Observable<Product[]>;

  abstract getProduct(productId: number): Observable<Product>;

  abstract getProductsByType(cityId: number, typeId: number): Observable<Product[]>;

  abstract searchProducts(cityId: number, term: string, limit: number): Observable<Product[]>;
}
