import {NgModule} from '@angular/core';
import {ExtraOptions, Router, RouterModule, Routes} from '@angular/router';
import {ApplicationStateData} from './@core/interfaces/market/application-state';

const desktopRoutes: Routes = [
  {
    path: 'market',
    loadChildren: () => import('./market/module/desktop/market-desktop.module')
      .then(m => m.MarketDesktopModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('./@auth/components/module/desktop/auth-components-desktop.module')
      .then(m => m.AuthComponentsDesktopModule),
  },
  { path: '', redirectTo: 'market', pathMatch: 'full' },
  { path: '**', redirectTo: 'market' },
];

const mobileRoutes: Routes = [
  {
    path: 'market',
    loadChildren: () => import('./market/module/mobile/market-mobile.module')
      .then(m => m.MarketMobileModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('./@auth/components/module/mobile/auth-components-mobile.module')
      .then(m => m.AuthComponentsMobileModule),
  },
  { path: '', redirectTo: 'market', pathMatch: 'full' },
  { path: '**', redirectTo: 'market' },
];


const config: ExtraOptions = {
  useHash: false,
  scrollPositionRestoration: 'enabled'
};

@NgModule({
  imports: [RouterModule.forRoot(desktopRoutes, config)],
  exports: [RouterModule]
})
export class AppRoutingModule {

  public constructor(private router: Router,
                     private applicationStateService: ApplicationStateData) {

    if (applicationStateService.getIsMobileResolution()) {
      router.resetConfig(mobileRoutes);
    }
  }
}
